export class BadgeNotificationsHelper {
  /* Returns n if n <= 99
   * or 99+ if n > 99
   */
  static prepareBadgeNotificationNumber(number){
    const limit = 99;
    let n = parseInt(number, 10); // in case we were given a numeric string
    n = n <= limit ? n : (limit + '+');
    return n;
  }
}
