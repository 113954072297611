<template>
  <div class="contacts-main-wrapper flex-row"
       :class="{'flex-row-nowrap' : this.flexDirection === 'column'}"
  >
    <mu-circular-progress
      v-if="this.list && !this.list.length && this.contactListLoading"
      class="demo-circular-progress"
      :size="36"></mu-circular-progress>
    <div class="companies-wrapper"
         :class="{
           'column': this.flexDirection === 'column',
           'row': this.flexDirection === 'row',
           'flex-rowwrap': this.flexDirection === 'flex-rowwrap',
           'companies-wrapper-fixed' : this.flexDirection === 'column',
           'has-dimmer' : this.perfectScrollShowDimmer
         }"
         v-if="list && list.length"
    >
            <span class="dimmer"
                  :style="{
                    'opacity': this.perfectScrollShowDimmer ? 1 : 0
                  }"
                  v-if="flexDirection === 'column'"
            ></span>

      <component
        v-bind:is="getListComponentName()"
        :options="{
          suppressScrollX: true,
          wheelSpeed: 1,
          wheelPropagation: false
        }"
        :class="[this.flexDirection, {'scroll-height': this.flexDirection === 'column'}]"
        @ps-scroll-y="perfectScrollHandlerY($event)"
        ref="scrollerContacts"
      >
        <div v-for="(item, key) in list"
             :key="item.id"
             class="company contact"
             @click="showContact(item.id, $event)"
             :data-has-messages="item.unread_messages !== 0 ? 'yes' : 'no'"
             :ref="'contactBadge_'+item.id"
             :class="{
                 'chosen': item.id === contact_id && $route.name !== 'promo-page-events-contacts' && $route.name !== 'promo-page-events-contacts-messages',
                 'company-last': key === list.length - 1
               }">
          <div class="avatar">
            <img :src="setAvatar(item.photo_url)" alt="image"/>
          </div>
          <div class="content">
            <div class="title">
              <span v-if="item.name">{{ item.name }} </span>
              <span v-if="item.surname">{{ item.surname }}</span>
              <span v-if="!item.name && !item.surname"> {{ $t('contacts.info.noname') }} </span>
            </div>
            <div class="subtitle">{{ item.city }} {{ item.country }}</div>
            <div class="info"><span v-if="item.company_name">{{ item.company_name + ',' }}</span> <span
              v-if="item.company_position">{{ item.company_position }}</span></div>
          </div>
          <mu-circular-progress v-if="item.id === contact_id && contactListLoading"
                                class="inner-loading-circle"
                                :size="26"></mu-circular-progress>
          <div class="contact-actions">
            <div class="unread-messages" v-if="item.unread_messages">
              <span class="badge-notification">{{ prepareBadgeNotificationNumber(item.unread_messages) }}</span>
            </div>
            <div class="fav">
              <mu-checkbox
                v-model="item.is_favorite"
                uncheck-icon="star_outline"
                checked-icon="star"
                @click.stop="toggleFavorite(item)"
              ></mu-checkbox>
            </div>
          </div>
        </div>
      </component>

    </div>
    <div v-else class="no-events">
      <span v-if="contactListType === 'recommended' && !this.contactListLoading">{{
          $t('contacts.noRecommendedContactsYet')
        }}</span>
      <span v-if="contactListType === 'own' && !this.contactListLoading">{{
          $t('contacts.noFavoriteContactsYet')
        }}</span>
      <span v-if="contactListType === 'bymessages' && !this.contactListLoading">{{
          $t('contacts.noMessageContactsYet')
        }}</span>
    </div>
    <router-view
      class="two-cols-wrapper"
      v-if="list && list.length"
      name="a"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import htmlDiv from '../../htmlElements/div';
import badgeNotificationsMixin from '@/mixins/badge-notifications.mixin';
import _cloneDeep from 'lodash.clonedeep';

export default {
  name: 'promo-contacts',
  components: {htmlDiv},
  computed: {
    ...mapState({
      contactList: state => state.contactStore.contactList.List,
      contactListType: state => state.promoStore.contactListType,
      // badgeNotifications: state => state._badgeNotificationsStore.badgeNotifications.data,
      // badgeNotificationsPollCount: state => state._badgeNotificationsStore.badgeNotificationsPollCount,
      contactListLoading: state => state.contactStore.contactListLoading
    }),
    ...mapGetters('_badgeNotificationsStore', {
      badgeNotifications: 'getNotifications',
      badgeNotificationsPollCount: 'getNotificationsPollData'
    }),
    contactId() {
      return this.$route.params.contact_id ? Number(this.$route.params.contact_id) : null;
    },
  },
  mixins: [badgeNotificationsMixin],
  watch: {

    contactId: {
      immediate: false,
      handler() {
        if (!this.contactId) {
          return;
        }
        this.contact_id = this.contactId;
        this.scrollOnTopByContactId();
      }
    },

    contactList: {
      deep: true,
      handler(newValue) {
        // Contacts with no name have to be at the end of the list
        let haveNames = [];
        let noNames = [];
        let item;
        for (let i = 0; i < newValue.length; i++) {
          item = _cloneDeep(newValue[i]);
          if ((!item.name || item.name === ' ') && !item.surname) {
            noNames.push(item);
          } else {
            haveNames.push(item);
          }
        }
        this.list = haveNames.concat(noNames);
      }
    },
    contactListType: {
      handler() {
        this.list = [];
        this.$nextTick(() => {
          if (this.contactListType === 'bymessages') {
            this.$router.push({name: 'promo-page-events-contacts-messages'}).catch(() => {
            });
          } else {
            this.$router.push({name: 'promo-page-events-contacts'}).catch(() => {
            });
          }
          this.callContactsList();
        })
      }
    },
    badgeNotifications: {
      deep: true,
      handler(newVal, oldVal) {
        // Re-renders the contact list if badge notification changed.
        // This is just to update badge notifications in a single contact.
        // On page load, we have badgeNotifications set to 0, 0
        // On first update, in case of non-zero badges, we will inevitably have
        // newVal.* !== oldVal.* which will trigger contactsList reload
        // But contactsList already has proper amounts in unread_messages,
        // so let's skip this handler until pollingCount is 2 or more
        if (this.badgeNotificationsPollCount < 2) {
          return;
        }
        if (newVal && newVal.messages_count !== oldVal.messages_count) {
          this.callContactsList();
        }
      }
    },
  },
  data() {
    return {
      list: [],
      favorite: false, //TODO: need set is_favorite,
      id: null,
      event_id: null,
      contact_id: null,
      isCheckedId: [],
      chosen: false,
      contactKey: null,
      flexDirection: 'flex-rowwrap',
      perfectScrollShowDimmer: false,
      perfectScrollScrollTop: 0,
    }
  },
  created() {
    this.contact_id = Number(this.$route.params.contact_id);
  },
  mounted() {
    if (this.$route.params.contact_id) {
      this.scrollOnTopByContactId();
    }
  },
  updated() {
    if (this.$route.params.contact_id) {
      this.flexDirection = 'column';
    } else {
      this.flexDirection = 'flex-row';
    }
  },
  methods: {
    ...mapActions("contactStore", ["callContactListMy", "callContactListAll", "callContactListByMessages"]),
    getListComponentName() {
      if (this.flexDirection === 'column') {
        return 'perfect-scrollbar';
      }
      return 'htmlDiv';
    },
    perfectScrollHandlerY(event) {
      if (!event || !event.target) {
        return;
      }

      // show gradient dimmer at top
      this.perfectScrollShowDimmer = event.target.scrollTop > 0;

      this.perfectScrollScrollTop = event.target.scrollTop + 25; // account for padding
    },
    callContactsList() {
      const data = {
        event_id: this.$route.params.eventId,
      };

      switch (this.contactListType) {
        case "all":
          this.callContactListAll(data);
          break;
        case "own":
          this.callContactListMy(data);
          break;
        case "bymessages":
          this.callContactListByMessages(data);
          break;
        case "recommended":
          this.list = [];
          break;
        default:
          this.list = [];
      }
    },
    scrollOnTopByContactId(contactId) {
      if (typeof contactId === 'undefined') {
        contactId = this.$route.params.contact_id;
      }
      const chosen = this.$refs['contactBadge_' + contactId];
      if ((typeof contactId === 'undefined') || !chosen) {
        setTimeout(this.scrollOnTopByContactId, 333);
        return;
      }
      this.scrollOnTop({
        target: chosen[0]
      });
    },
    async showContact(contactId, event) {
      // if (this.contact_id === contactId) return; // AW-611

      this.contact_id = contactId;
      let actionName = 'callContactListAll';
      if (this.contactListType === 'own') {
        actionName = 'callContactListMy';
      }
      if (this.contactListType === 'bymessages') {
        actionName = 'callContactListByMessages';
      }
      if (!event.target.classList.contains('material-icons')) { // TODO: avoid .material-icons class dependency
        await this.$store.dispatch('contactStore/' + actionName, {event_id: this.$route.params.eventId,})
        await this.$router.push({
          name: 'promo-page-contacts-contact',
          params: {contact_id: contactId}
        }).catch(() => {
        });
      }
    },
    showContactCompany(contact_id) {
      if (contact_id) {
        this.$store.dispatch('promoStore/getPromoPageContactId', {
          event_id: this.$route.params.eventId,
          contact_id: contact_id
        });
      }
    },
    toggleFavorite(contact) {

      // state of is_favorite come into this method AFTER being changed by v-model
      if (contact.is_favorite === true) {
        let payload = {
          event_id: this.$route.params.eventId,
          contact_id: contact.id,
        };

        this.$store.dispatch('contactStore/addFavContact', payload).then(() => {
          this.callContactsList()
        })
      } else {
        let payload = {
          event_id: this.$route.params.eventId,
          contact_id: contact.id,
        };

        this.$store.dispatch('contactStore/removeFavContact', payload).then(() => {
          this.callContactsList()
        })
      }
    },
    setAvatar(url) {
      if (url) {
        return url
      }
      return require('../../../../assets/images/no-avatar-300x300.png');
    },
    scrollOnTop(event) {
      let scroller = this.$refs.scrollerContacts.$el;
      let halfHeight = 70;
      let difference = Math.max(0, event.target.offsetTop - halfHeight) - scroller.scrollTop;
      let count = 10; // no more than [count] timeouts
      let step = difference / 10; // pixel amount
      const stepper = function () {
        scroller.scrollTop += step;
        count--;
        if (count > 0) {
          setTimeout(stepper, 11);
        }
      }
      setTimeout(stepper, 11);

      if (this.flexDirection === 'column') {
        return false; // stopping from passing the event into further scroll handlers
      }
    }
  }
}

</script>

<style scoped lang="scss">
.contacts-main-wrapper {
  width: 100%;
}

// AW-734
.two-cols-wrapper {
  width: calc(100% - 500px); // 100% - ширина колонки со списком - паддинги страницы левый правый - ширина сайдбара
}

.scroll-height {
  height: calc(100vh - 207px);
  position: relative;
  width: 420px;
}

.head-company-nav {
  text-align: left;
  font-family: "Point Regular", sans-serif;
  font-size: 10px;
  letter-spacing: 0;
  color: #b5b5b5;
  position: relative;
  padding: 8px 0 8px 10px;
}

.flex-row {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-items: flex-start;
}

.companies-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  border-radius: 25px;
  padding-bottom: 30px;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 1;
}

.chosen {
  transform: translate(30px, 0);
  pointer-events: none;

  &.contact {
    cursor: default;
  }

  .fav {
    display: none;
  }

  .badge-notification {
    transform: translateX(-8px);
  }
}

.head-nav {
  text-align: left;
  font-family: Point, "Point Regular", sans-serif;
  font-size: 10px;
  letter-spacing: 0;
  color: #b5b5b5;
  padding-left: 38px;
  padding-top: 28px;
  padding-bottom: 10px;
}

.flex-rowwrap {
  flex-flow: row wrap;
}

.flex-row-nowrap {
  flex-flow: row nowrap;
}

.column {
  flex-flow: column wrap;
}

.company {
  //width: 392px;
  //height: 100px;
  width: 400px;
  height: 130px;
  background: $biletum-white 0 0 no-repeat padding-box;
  box-shadow: 10px 10px 30px #3754aa1a;
  border-radius: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 23px;
  margin: 0 8px 10px;
  cursor: pointer;

  .avatar {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    opacity: 1;

    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }


  .content {
    padding-left: 11px;
    align-self: flex-start;
    max-height: 54px;
    overflow: hidden;
    text-overflow: ellipsis;

    .title {
      text-align: left;
      font-size: 13px;
      font-family: Point, "Point Regular", sans-serif;
      font-weight: bold;
      letter-spacing: 0;
      color: $biletum-black;
      max-width: 240px;
    }

    .subtitle {
      text-align: left;
      font-family: Point, "Point Regular", sans-serif;
      font-size: 12px;
      letter-spacing: 0;
      color: $biletum-black;
    }

    .info {
      text-align: left;
      font-size: 11px;
      font-family: Point, "Point Regular", sans-serif;
      letter-spacing: 0;
      color: $biletum-black;
    }
  }

  .contact-actions {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-right: -10px;
  }

  .badge-notification {
    font-size: 1.1rem;
    margin-right: 1.4rem;
    min-height: 1.8rem;
    min-width: 1.8rem;
  }
}


/* AW-415 */
.companies-wrapper-fixed {
  padding-left: 402px;
  padding-top: 25px;
  position: relative;

  .column {
    position: fixed;
    //margin-left: -400px;
    margin-left: -412px;
    height: calc(100vh - 188px);

    .company {
      transition: transform 0.2s, border-radius 0.2s;
    }

    .company-last {
      margin-bottom: 200px; /* Чтобы можно было скроллиться дальше при выборе .chosen */
    }
  }

  .dimmer {
    width: 415px;
    height: 60px;
    pointer-events: none;
    position: fixed;
    transition: opacity 0.5s;
    left: 87px;
    top: 178px;
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(244, 244, 244, 1) 0, rgba(244, 244, 244, 1) 25px, rgba(244, 244, 244, 0));
  }
}

/* AW-415 END --------- */

.no-events {
  padding: 34px;
  margin: auto;
}

.demo-circular-progress {
  left: calc(50% - 16px);
  position: absolute;
}

.inner-loading-circle {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
</style>
